import {React, useState, useEffect} from 'react'
import Header from '../../component/Header'
import "react-datepicker/dist/react-datepicker.css";
import {db,  collection, query, where, getDocs} from '../../firebase/index';
import Chart from '../../component/Chart';
export default function Home() {
    const [startDate, setStartDate] = useState(new Date());
    const [totalPayment, setTotalPayment] = useState({
        '01' : 0,
        '02' : 0,
        '03' : 0,
        '04' : 0,
        '05' : 0,
        '06' : 0,
        '07' : 0,
        '08' : 0,
        '09' : 0,
        '10' : 0,
        '11' : 0,
        '12' : 0
    })
    const [totalAttendance, setTotalAttendance] = useState({
        '01' : 0,
        '02' : 0,
        '03' : 0,
        '04' : 0,
        '05' : 0,
        '06' : 0,
        '07' : 0,
        '08' : 0,
        '09' : 0,
        '10' : 0,
        '11' : 0,
        '12' : 0
    })
    const fetchAttendance = async () => {
        setTotalAttendance([])
        const temp = {
            '01' : 0,
            '02' : 0,
            '03' : 0,
            '04' : 0,
            '05' : 0,
            '06' : 0,
            '07' : 0,
            '08' : 0,
            '09' : 0,
            '10' : 0,
            '11' : 0,
            '12' : 0
        }
        const formattedStartDate = startDate.toISOString().slice(0, 4) + '-01-01';
        const formattedEndDate = (parseInt(formattedStartDate.slice(0, 4)) + 1).toString() + '-01-01';
        const q = query(collection(db, "attendance"), where('date', '<',  formattedEndDate) , where('date', '>=', formattedStartDate));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        const data = {
            id: doc.id,
            ...doc.data()
        }
        temp[data.date.slice(5, 7)] += 1;
        }
    )
        setTotalAttendance(temp);
    }


    const fetchPayment = async () => {
        setTotalPayment([])
        const temp = {
            '01' : 0,
            '02' : 0,
            '03' : 0,
            '04' : 0,
            '05' : 0,
            '06' : 0,
            '07' : 0,
            '08' : 0,
            '09' : 0,
            '10' : 0,
            '11' : 0,
            '12' : 0
        }
        const formattedStartDate = startDate.toISOString().slice(0, 4) + '-01-01';
        const formattedEndDate = (parseInt(formattedStartDate.slice(0, 4)) + 1).toString() + '-01-01';
        const q = query(collection(db, "payments") , where('date', '<',  formattedEndDate) , where('date', '>=', formattedStartDate));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        const data = {
            id: doc.id,
            ...doc.data()
        }
        temp[data.date.slice(5, 7)] += data.amount;
        }
    )
    setTotalPayment(temp);
    }


    useEffect(()=>{
        fetchPayment();
        fetchAttendance();
    }, [])

    return (
        <div>
        <Header/>
        <div className="flex justify-around bg-gray-100">
            <div className="p-10">
                <h2 className="text-3xl font-bold">
                    Earning by Month
                </h2>
                <div className="mx-auto flex">
                    <Chart data={totalPayment} color={'#295496'}/>
                </div>
            </div>
            <div className="p-10">
                <h2 className="text-3xl font-bold">
                    Attendance by Month
                </h2>
                <div className="mx-auto flex">
                    <Chart data={totalAttendance} color={'#289c4c'}/>
                </div>
            </div>
        </div>
        </div>

    );
}
