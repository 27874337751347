import React from 'react';
import { Button, Navbar } from 'flowbite-react';
import { useNavigate } from 'react-router-dom'; 
import { logout } from '../firebase/auth'; 

function Component() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await logout(); 
      navigate('/login'); 
    } catch (error) {

      console.error('Error signing out: ', error);
    }
  };

  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <span className="text-blue-800 self-center whitespace-nowrap text-xl font-semibold dark:text-white">BlueBell</span>
      </Navbar.Brand>
      <div className="flex md:order-2 gap-2">
        <Button 
          color="failure" 
          onClick={handleSignOut}
        >
          Sign Out
        </Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href="/teacher">Teacher</Navbar.Link>
        <Navbar.Link href="/student">Student</Navbar.Link>
        <Navbar.Link href="/group">Group Attendance</Navbar.Link>
        <Navbar.Link href="/payment">Payment</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Component;