import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import router from './router';
import { AuthProvider } from './firebase/authContext';
import { RouterProvider } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <RouterProvider router={router}></RouterProvider>
    </AuthProvider>
);
