import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function Chart({data, color}) {

    return (
        <BarChart

        xAxis={[{ scaleType: 'band', data: ['Jan', 'Feb', 'Mar', 'Apr','May','June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }]}
        series={[{color: color,  data: [data['01'],data['02'],data['03'],data['04'],data['05'],data['06'],data['07'],data['08'],data['09'],data['10'],data['11'],data['12']] }]}
        width={600}
        height={400}
        />
    );
}