import {React, useState, useEffect} from 'react'
import Header from '../../component/Header'
import Card from '../../component/Card'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {orderBy, db,  collection, query, where, getDocs, deleteDoc, doc } from '../../firebase/index';
export default function Teacher() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const fetchTeachers = async () => {
    const q = query(collection(db, "teachers"),orderBy('name'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = {
        id: doc.id,
        ...doc.data()
      }
      setTeachers((teachers) => [...teachers, data]);
    });
  }
  const deleteRecord = async (id) => {
    try {
      await deleteDoc(doc(db, "attendance", id));
      setAttendance(currentAttendance => 
        currentAttendance.filter(record => record.id !== id)
      );
      alert("Document successfully deleted!");
    } catch (error) {
      alert("Error removing document: ", error);
    }
  };

  const fetchAttendance = async () => {
    setAttendance([])
    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = endDate.toISOString().slice(0, 10);
    const q = query(collection(db, "attendance") ,orderBy("date"), where("teacher_id", "==", selectedTeacherId), where('date', '<=',  formattedEndDate) , where('date', '>=', formattedStartDate));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = {
        id: doc.id,
        ...doc.data()
      }
      setAttendance((attendance) => [...attendance, data]);
    });
  }

  useEffect(()=>{
    fetchTeachers()
  }, [])

  return (
    <div>
      <Header/>
      <div className="p-10 bg-gray-100">
          <h1 className="text-blue-800 text-4xl font-bold">
            Attendance
          </h1>
      </div>
      <div className='flex flex-wrap justify-around px-20 py-5 bg-gray-100 '>
        <div>
            <div className="mb-2">
              Start Date: 
            </div>
            <div>
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            </div>
        </div>
        <div>
          <div className="mb-2">
            End Date:
          </div>
          <div>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
          </div>
        </div>
        <div>
          <div className="max-w-sm mx-auto">
            <label for="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Teacher: </label>
            <select onChange={(e)=>{setSelectedTeacherId(e.target.value)}} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option selected>Teacher</option>
              {teachers.map((t, index)=>{
                return(
                  <option key={index} value={t.id}>{t.name}</option>
                )
              })}
            </select>
          </div>

        </div>
      </div>
      <div class="flex justify-center items-center h-12 bg-gray-100 py-10">
        <button onClick={()=>{fetchAttendance()}} class="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 rounded-lg text-xl px-5 py-2.5 text-center">
          Fetch
        </button>
      </div>
      <div className='flex flex-wrap justify-around gap-1 py-10 bg-black md:px-10'>
        {
          attendance.map((a, index) => {
            return (
              <div key={index} className='lg:w-1/4 xl:w-1/5 p-2'>
                <Card data={a} 
                deleteRecord={ deleteRecord }/>
              </div>
            );
          })
        }
      </div>

    </div>

  );
}
