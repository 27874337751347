// src/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../firebase/auth'; // Make sure this path matches your Firebase auth functions file
import { useAuth } from '../../firebase/authContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth(); // Assuming your AuthContext provides this function

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    try {
      const userCredential = await login(email, password);
      setCurrentUser(userCredential.user); // Update the current user in context
      navigate('/'); // Navigate to the homepage after successful login
    } catch (error) {
      setError('Failed to log in. Please check your email and password.')
      console.log(error); // Set error message on failure
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="max-w-md w-full mx-auto bg-white p-8 border border-gray-300 rounded-lg">
        <h2 className="text-2xl font-bold text-center mb-4">Login</h2>
        {error && <p className="bg-red-100 text-red-700 p-3 rounded">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="text-sm font-bold text-gray-600 block">Email</label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="text-sm font-bold text-gray-600 block">Password</label>
            <input
              type="password"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 rounded text-white text-sm font-bold">Log In</button>
        </form>
      </div>
    </div>
  );
}

export default Login;