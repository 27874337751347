// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAggregateFromServer, sum, orderBy, getFirestore, collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "bluebell-85dd5.firebaseapp.com",
  projectId: "bluebell-85dd5",
  storageBucket: "bluebell-85dd5.appspot.com",
  messagingSenderId: "828949980387",
  appId: "1:828949980387:web:21ec2c4b55573d6acb91d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export {getAggregateFromServer, sum, auth, app, db, orderBy, collection, query, where, getDocs, doc, deleteDoc}