import React from 'react'
export default function Card({data,  deleteRecord }) {
  
    return (
      <div className='md:text-lg text-sm text-gray-700 w-full bg-white shadow-md rounded-lg overflow-hidden'>
        <div className='p-5'>
          <div>
            Course: {data.course} 
          </div>
          <div>
            Date: {data.date}
          </div>
          <div>
            Student: {data.student_name}
          </div>
          <div>
            Teacher: {data.teacher_name}
          </div>
          {/* Add a delete button */}
          <button onClick={()=>{deleteRecord(data.id)}} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete Record
          </button>
        </div>
      </div>
    );
  }