import React, { useContext, useState, useEffect } from 'react';
import { auth } from '.';
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, user => {
        setCurrentUser(user);
        setLoading(false); // Update loading state once Firebase is done initializing
      });
  
      return unsubscribe;
    }, []);
  
    const value = {
      currentUser,
      setCurrentUser,
      loading, // Include loading in the context value
    };
  
    return (
      <AuthContext.Provider value={value}>
        {!loading && children} 
      </AuthContext.Provider>
    );
  }