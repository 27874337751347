import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Teacher from '../page/Teacher/index.js';
import Login from '../page/Login';
import Student from '../page/Student';
import NotFound from '../page/NotFound';
import Group from '../page/Group';
import Payment from '../page/Payment.js';
import Home from '../page/Home/index.js';
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Home></Home>
      </PrivateRoute>
    )
  },
  {
    path: "/teacher",
    element: (
      <PrivateRoute>
        <Teacher/>
      </PrivateRoute>
    ),
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "student",
    element: <PrivateRoute><Student/></PrivateRoute>
  },
  {
    path: 'group',
    element: <PrivateRoute><Group/></PrivateRoute>
  },
  {
    path: 'payment',
    element: <PrivateRoute><Payment/></PrivateRoute>
  },
  {
    path: '*',
    element: <NotFound/>
  },
]);


export default router