import React from 'react'
export default function GroupCard({data,  deleteRecord }) {
  
    return (
      <div className='md:text-lg text-base text-gray-700 w-full bg-white shadow-md rounded-lg overflow-hidden'>
        <div className='p-5'>
          <div>
            Course: {data.course}
          </div>
          <div>
            Date: {data.date}
          </div>
          <div>
            Students: 
          </div>

          <div className='rounded-xl p-3 bg-gray-200 text-base md:text-lg text-gray-700'>
            {data.students.map((s)=>{
                return(
                    <div>
                        <div className="font-bold">
                          {s.name}
                          {" ("}{s.status=="attend"?
                        <span className="text-green-400 font-bold">Attend</span>:
                        <span className="text-red-400 font-bold">Absent</span>}{")"}</div>

                    </div>
                )
            })}
          </div>
          <div>
            Teacher: {data.teacherName}
          </div>
          {/* Add a delete button */}
          <button onClick={()=>{deleteRecord(data.id)}} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete Record
          </button>
        </div>
      </div>
    );
  }