import { Navigate } from 'react-router-dom';
import { useAuth } from '../firebase/authContext';

function PrivateRoute({ children }) {
  const { currentUser, loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>; 
  }
  return currentUser ? children : <Navigate to="/login" replace />;
}

export default PrivateRoute;